import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL; // Replace with your actual API URL

const axiosServices = axios.create({
  baseURL: API_BASE_URL,
});

axiosServices.interceptors.request.use(
  (config) => {
    // Retrieve and parse the 'user' JSON object from localStorage
    const storedUser = localStorage.getItem("user");
    let token;

    if (storedUser) {
      try {
        const userObject = JSON.parse(storedUser); // Parse JSON
        token = userObject.serviceToken; // Extract serviceToken instead of token
      } catch (error) {
        console.error("Error parsing token from localStorage:", error);
      }
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      console.warn("Token not found. Authorization header not set.");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosServices;
