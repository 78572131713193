import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Layout from "./layout";
import Projects from "./pages/Projects";
import Support from "./pages/Support";
import Chat from "./pages/Chat";
import { useAuth } from "./context/AuthContext";

function PrivateRoute({ element, isAuthenticated }) {
  return isAuthenticated ? element : <Navigate to="/login" />;
}

function PublicRoute({ element, isAuthenticated }) {
  console.log("PublicRoute - isAuthenticated:", isAuthenticated);
  return isAuthenticated ? <Navigate to="/projects" /> : element;
}

function AppRoutes() {
  const authContext = useAuth();
  const isAuthenticated = authContext?.user?.serviceToken;
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute
              element={<Login />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute
              element={<Login />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/register"
          element={
            <PublicRoute
              element={<Register />}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route element={<Layout />}>
          <Route
            path="/projects"
            element={
              <PrivateRoute
                element={<Projects />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/support"
            element={
              <PrivateRoute
                element={<Support />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute
                element={<Chat />}
                isAuthenticated={isAuthenticated}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRoutes;
