import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../theme";
import { TextField, Typography, Box, useMediaQuery } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import logo from "../assets/images/logo.png";
import LoadingButton from "@mui/lab/LoadingButton";

function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();

  // State for form values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // State for error handling
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Validation function
  const validate = () => {
    let valid = true;

    // Email validation
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    // Password validation
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      valid = false;
    } else {
      setPasswordError("");
    }

    return valid;
  };

  const handleLogin = async () => {
    if (validate()) {
      setIsLoading(true);

      try {
        const result = await login({ email, password });

        if (result.status === 200) {
          setLoginError("");
          navigate("/projects");
        } else {
          setLoginError(result.response?.data?.message || "Login failed.");
        }
      } catch (error) {
        setLoginError("An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.background.dark,
        margin: matchDownSm ? 2 : 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: 900,
          bgcolor: "white",
          boxShadow: 3,
          flexDirection: matchDownSm ? "column-reverse" : "row",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        {/* Left Side - Sign In */}
        <Box
          sx={{
            flex: 1,
            padding: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleLogin();
          }}
        >
          <Typography variant="h5" gutterBottom>
            Sign In
          </Typography>

          <TextField
            label="Email Address"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />

          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />

          {loginError && (
            <Typography variant="body2" color="error" align="center">
              {loginError}
            </Typography>
          )}

          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogin}
            sx={{ mt: 2 }}
            size="large"
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            Login
          </LoadingButton>

          <Typography sx={{ mt: 2, textAlign: "center" }}>
            Don't have an account?{" "}
            <span
              style={{
                color: "green",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => navigate("/register")}
            >
              Sign Up
            </span>
          </Typography>
        </Box>

        {/* Right Side - Welcome */}
        <Box
          sx={{
            flex: 1,
            background: theme.palette.background.light,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 4,
            color: "white",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={1}
            sx={{ flex: "0 0 auto", marginBottom: 3 }}
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: "300px", height: "auto" }}
            />
          </Box>

          <Typography
            sx={{ fontSize: 17, color: "black" }}
            mb={3}
            align="center"
          >
            A virtual AI agent at a fraction of cost to understand legacy
            codebases!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
