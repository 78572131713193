import { useState } from "react";
import axiosServices from "../utils/axiosServices";
import Loader from "../components/loader";
import { useAuth } from "../context/AuthContext";
import { MermaidDiagram } from "@lightenna/react-mermaid-diagram";

function Mermaid({ projectId }) {
  const [mermaidCode, setMermaidCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  const getMermaidCode = async () => {
    try {
      setIsLoading(true);
      const input = {
        email: user?.user?.email,
        projectId: projectId,
      };
      const response = await axiosServices.post("/projects/mermaid", input);
      response && response.data && setMermaidCode(response.data.mermaid_code);
    } catch (error) {
      console.error("Error fetching projects:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useState(() => {
    projectId && getMermaidCode();
  }, [projectId]);

  return (
    <>
      {isLoading ? (
        <Loader open={isLoading} />
      ) : (
        <MermaidDiagram>{mermaidCode}</MermaidDiagram>
      )}
    </>
  );
}

export default Mermaid;
