import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useAuth } from "../context/AuthContext";
import logo from "../assets/images/logo.png";
import theme from "../theme";
import LoadingButton from "@mui/lab/LoadingButton";



function Register() {
  const navigate = useNavigate();
  const { register, login } = useAuth();

  // State for form values
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // State for error handling
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [registerError, setRegisterError] = useState("");
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);

  // Validation function
  const validate = () => {
    let valid = true;

    // Full Name validation: required field
    if (!fullName.trim()) {
      setFullNameError("Full Name is required.");
      valid = false;
    } else {
      setFullNameError("");
    }

    // Email validation: simple regex for email format
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setEmailError("Please enter a valid email address.");
      valid = false;
    } else {
      setEmailError("");
    }

    // Password validation: must be at least 6 characters
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      valid = false;
    } else {
      setPasswordError("");
    }

    // Confirm Password validation: must match password
    if (confirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match.");
      valid = false;
    } else {
      setConfirmPasswordError("");
    }

    return valid;
  };

  const handleRegister = async () => {
    if (validate()) {
      setIsLoading(true);
      const result = await register({ name: fullName, email, password });
      if (result.status !== 201) {
        setRegisterError(result.response?.data?.message);
        setIsLoading(false);
      } else {
        setRegisterError("");
        if (result.status === 201) {
          const result = await login({ email, password });
          if (result.status !== 200) {
            console.log(result.response.data.message);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            navigate("/projects");
          }
        } else {
          setRegisterError("Something went wrong! Please try later");
          setIsLoading(false);
        }
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.background.dark,
        margin: matchDownSm ? 2 : 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: 900,
          bgcolor: "white",
          boxShadow: 3,
          flexDirection: matchDownSm ? "column-reverse" : "row",
          borderRadius: 2, // Add borderRadius for rounded corners
          overflow: "hidden", // Prevents child elements from spilling out
        }}
      >
        {/* Left Side - Sign Up */}
        <Box
          sx={{
            flex: 1,
            padding: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleRegister();
            }
          }}
        >
          <Typography variant="h5" gutterBottom>
            Sign Up
          </Typography>

          <TextField
            label="Full Name"
            fullWidth
            margin="normal"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            error={!!fullNameError} // Show error if fullNameError state is not empty
            helperText={fullNameError} // Display the error message
          />

          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError} // Show error if emailError state is not empty
            helperText={emailError} // Display the error message
          />

          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError} // Show error if passwordError state is not empty
            helperText={passwordError} // Display the error message
          />

          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!confirmPasswordError} // Show error if confirmPasswordError state is not empty
            helperText={confirmPasswordError} // Display the error message
          />

          {registerError && (
            <Typography variant="h8" gutterBottom color="error" align="center">
              {registerError}
            </Typography>
          )}

          <LoadingButton
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 2,
              color: "white",
            }}
            onClick={handleRegister}
            size="large"
            loading={isLoading}
            loadingIndicator="Loading…"
          >
            Sign Up
          </LoadingButton>

          <Typography sx={{ mt: 2, textAlign: 'center' , color: "black"}}>
           Already have an account?{' '}
          <span
           style={{ color: 'green', cursor: 'pointer', textDecoration: 'underline' }}
           onClick={() => navigate("/login")}
          >
           Sign In
           </span>
          </Typography>

        </Box>

        {/* Right Side - Welcome */}
        <Box
          sx={{
            flex: 1,
            background: theme.palette.background.light,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: 4,
            color: "white",
            
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            p={1}
            sx={{  flex: "0 0 auto",  marginBottom: 3}} 
          >
            <img src={logo} alt="logo" style={{ width: '300px', height: 'auto' }} />
          </Box>

          <Typography sx={{ fontSize: 17 , color :'black'}} mb={3} align="center">
            A virtual AI agent at a fraction of cost to understand legacy
            codebases!
          </Typography>   
        </Box>
      </Box>
    </Box>
  );
}

export default Register;
