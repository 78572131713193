import { useEffect, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import axiosServices from '../utils/axiosServices';

const ZohoAsapWidget = () => {
  const { user } = useAuth();

  const getJwtTokenCallback = useCallback(
    (successCallback, failureCallback) => {
      const token = user?.serviceToken;
      if (!token) {
        failureCallback(new Error('No service token available'));
        return;
      }

      axiosServices
        .post(
          '/auth/refresh-zoho-jwt',
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          if (response.data?.zohoJwt) {
            successCallback(response.data.zohoJwt);
          } else {
            failureCallback(new Error('Invalid JWT response'));
          }
        })
        .catch((error) => {
          console.error('Failed to get Zoho JWT:', error);
          failureCallback(error);
        });
    },
    [user?.serviceToken]
  );

  useEffect(() => {
    if (!user?.serviceToken) return;

    const initZoho = () => {
      if (window.ZohoDeskAsap) {
        window.ZohoDeskAsap.invoke('login', getJwtTokenCallback);
      }
    };

    window.ZohoDeskAsapReady = function (callback) {
      var asyncCalls = (window.ZohoDeskAsap__asyncalls = window.ZohoDeskAsap__asyncalls || []);
      if (window.ZohoDeskAsapReadyStatus) {
        callback && asyncCalls.push(callback);
        asyncCalls.forEach((cb) => cb && cb());
        window.ZohoDeskAsap__asyncalls = null;
      } else {
        callback && asyncCalls.push(callback);
      }
    };

    window.ZohoDeskAsapReady(initZoho);
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.id = 'zohodeskasapscript';
    s.defer = true;
    s.src = process.env.REACT_APP_ZOHO_ASAP_URL;

    document.head.appendChild(s);

    return () => {
      const script = document.getElementById('zohodeskasapscript');
      const domElement = document.getElementById('zohohc-asap-web-app-main');
      if (script) {
        script.remove();
      }
      if (domElement) {
        domElement.remove();
      }
    };
  }, [user, getJwtTokenCallback]);

  return null;
};

export default ZohoAsapWidget;
