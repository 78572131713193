import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Box, CssBaseline } from '@mui/material';
import { Logout, Menu as MenuIcon } from '@mui/icons-material';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import logo from '../assets/images/logo.png';
import ZohoSupport from '../components/support';

function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { user, logout, setUser } = useAuth();

  const handleLogout = () => {
    setDrawerOpen(false);
    setUser(null);
    logout();
    navigate('/login');
    window.location.reload();
  };

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  const sidebarItems = [
    { text: 'My Projects', path: '/projects' },
    // { text: "Support", path: "/support" },
  ];

  const drawerItems = (
    <Box sx={{ width: 250 }}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} p={1} width={'100%'}>
        <img src={logo} alt='logo' />
      </Box>
      <List sx={{ p: 2 }}>
        {sidebarItems.map((item) => (
          <ListItem
            key={item.text}
            onClick={() => handleNavigation(item.path)}
            sx={{
              mb: 1,
              borderRadius: '5px',
              cursor: 'pointer',
              backgroundColor: location.pathname === item.path ? 'primary.main' : 'inherit',
              color: location.pathname === item.path ? '#fff' : 'inherit',
              ':hover': {
                backgroundColor: 'primary.light',
              },
            }}
          >
            <ListItemText>{item.text}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <CssBaseline />

      <AppBar position='fixed'>
        <Toolbar>
          <IconButton edge='start' color='inherit' aria-label='menu' onClick={toggleDrawer} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>

          <Typography variant='h6' sx={{ flexGrow: 1 }}>
            Welcome {user?.user?.name}!
          </Typography>

          <IconButton color='inherit' onClick={handleLogout}>
            <Logout />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer anchor='left' open={isDrawerOpen} onClose={toggleDrawer}>
        {drawerItems}
      </Drawer>

      {/* Page content is rendered here using Outlet */}
      <Box sx={{ p: 1, mt: 9, width: '100%', maxWidth: 'none' }}>
        <Outlet />
      </Box>

      {/* Conditionally render ZohoSupport only if the user is authenticated and not on the login page */}
      {user && location.pathname !== '/login' && <ZohoSupport />}
    </>
  );
}

export default Layout;
