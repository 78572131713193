import { Container, Box, Typography, IconButton, TextField, InputAdornment, CircularProgress, Avatar, Button } from '@mui/material';
import { ArrowBack, Send } from '@mui/icons-material';
import { useCallback, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axiosServices from '../utils/axiosServices';
import { useAuth } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png';

function Chat() {
  const { state } = useLocation();
  const { project } = state ? state : null;
  const [message, setMessage] = useState('');
  const { user } = useAuth();
  const [messages, setMessages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const chatContainerRef = useRef(null);

  const getChatHistory = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosServices.get(`/messages?email=${user?.user?.email}&projectId=${project.project_id}`);
      response && response.data && setMessages(response.data);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.user?.email, project?.project_id]);

  useEffect(() => {
    project && getChatHistory();
  }, [project, getChatHistory]);

  useEffect(() => {
    messages && chatContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleBack = () => {
    window.history.back();
  };

  const handleSendMessage = async (userMessage) => {
    if (typeof userMessage === 'string' && userMessage.trim()) {
      const userQuestion = userMessage.trim();
      setMessage('');
      setIsMessageLoading(true);

      setMessages((prevMessages) => [...prevMessages, { role: 'user', content: userQuestion }]);

      try {
        const input = {
          userQuestion: userQuestion,
          projectId: project.project_id,
          email: user?.user?.email,
        };
        const response = await axiosServices.post(`/messages`, input);

        if (response && response.data) {
          setMessages((prevMessages) => [...prevMessages, { role: 'assistant', content: response.data?.result }]);
        } else {
          console.error('Unexpected API response structure:', response);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              role: 'error',
              content: 'Received an invalid response. Please try again.',
            },
          ]);
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: 'error',
            content: 'Failed to send message. Please try again.',
          },
        ]);
      } finally {
        setIsMessageLoading(false);
      }
    }
  };

  return (
    <Container
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: messages && messages.length > 0 ? 'calc(100vh - 90px)' : 'auto',
        justifyContent: 'space-between',
        transition: 'height 0.3s ease-in-out',
      }}
    >
      {/* Header */}
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mb={1}>
        <IconButton color='primary' onClick={handleBack}>
          <ArrowBack />
        </IconButton>
        <Typography variant='h6' gutterBottom ml={1} align='center'>
          Chat with {project.project_name}
        </Typography>
      </Box>

      {/* Chat messages container */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          mb: 1,
          p: 2,
          borderRadius: 2,
          bgcolor: '#f5f5f5', // Light grey background for messages
        }}
      >
        {/* chat UI */}
        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : messages && messages.length > 0 ? (
          <>
            <PerfectScrollbar>
              {messages &&
                messages.map((message, index) => (
                  <Box
                    key={`${message.role}-${index}`}
                    sx={{
                      justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start',
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: message.role === 'user' ? 'row-reverse' : 'row',
                        alignItems: 'flex-end', // Align messages at the bottom to resemble WhatsApp
                        mb: 1.5, // Add some margin between the message bubbles
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: message.role === 'user' ? 'primary.main' : '#e0e0e0', // Greenish background for user, grayish for others
                          color: '#000', // Make avatar text white
                          width: 32, // Adjust size to be smaller like WhatsApp avatars
                          height: 32,
                          fontSize: 14, // Smaller text inside avatar
                          mr: message.role === 'user' ? 0 : 1,
                          ml: message.role === 'user' ? 1 : 0,
                        }}
                      >
                        {message.role === 'user' ? 'U' : 'H'}
                      </Avatar>

                      <Box
                        sx={{
                          width: '60%',
                        }}
                      >
                        <Typography
                          sx={{
                            p: 1.5,
                            fontSize: '0.9rem', // Slightly smaller font size like WhatsApp
                            backgroundColor: message.role === 'user' ? 'primary.main' : '#e0e0e0', // Different background colors for user and others
                            borderRadius:
                              message.role === 'user'
                                ? '16px 0px 16px 16px' // WhatsApp style rounded corners for user
                                : '0px 16px 16px 16px', // Rounded corners for non-user
                            color: '#000000', // Use black text color for better readability
                            boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)', // Light shadow for chat bubble effect
                            wordBreak: 'break-word', // Ensure long words break correctly
                            overflow: 'auto',
                          }}
                        >
                          <Markdown>{message.content}</Markdown>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              {isMessageLoading && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      mb: 1,
                      ml: 4,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1.5,
                        bgcolor: '#f0f0f0',
                        borderRadius: '16px',
                        p: 1.5,
                        maxWidth: '70%',
                        color: '#000000',
                        fontSize: '0.9rem',
                        boxShadow: '0 1px 1px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          '& span': {
                            width: '8px', // Slightly larger for visibility
                            height: '8px',
                            margin: '0 3px', // More spacing for the bounce effect
                            borderRadius: '50%',
                            backgroundColor: '#000000',
                            animation: 'bounce 1.2s infinite ease-in-out',
                          },
                          '& span:nth-of-type(1)': {
                            animationDelay: '0s',
                          },
                          '& span:nth-of-type(2)': {
                            animationDelay: '0.2s',
                          },
                          '& span:nth-of-type(3)': {
                            animationDelay: '0.4s',
                          },
                        }}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </Box>
                    </Box>
                  </Box>

                  <style>
                    {`
                      @keyframes bounce {
                        0%, 80%, 100% {
                          transform: translateY(0);
                        }
                        40% {
                          transform: translateY(-8px); /* Adjust the height of the bounce */
                        }
                      }
                      `}
                  </style>
                </>
              )}
              <div ref={chatContainerRef} />
            </PerfectScrollbar>
          </>
        ) : (
          // New code for when there are no messages
          <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' height='100%' p={4}>
            <img src={logo} alt='Harmony Engine Logo' style={{ width: '150px', marginBottom: '20px' }} />
            <Typography variant='h5' align='center' gutterBottom>
              Let's untangle the code for
            </Typography>
            <Typography variant='h4' align='center' fontWeight='bold'>
              {project.project_name}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Chat input */}
      <Box
        sx={{
          p: 1, // Light border to separate from the chat
          bgcolor: 'white',
        }}
      >
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => handleSendMessage('List Top 20 FAQs for this project')}
            variant='outlined'
            sx={{
              borderRadius: '20px', // Rounded borders
            }}
            disabled={isMessageLoading}
          >
            List Top 20 FAQ's for this project
          </Button>
          <Button
            onClick={() => handleSendMessage('List Project Requirements')}
            variant='outlined'
            sx={{
              ml: 1,
              borderRadius: '20px', // Rounded borders
            }}
            disabled={isMessageLoading}
          >
            List Project Requirements
          </Button>
        </Box>
        <TextField
          size='small'
          fullWidth
          variant='outlined'
          placeholder='Ask me anything...'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage(message);
            }
          }}
          multiline
          maxRows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton color='primary' onClick={() => handleSendMessage(message)} size='small' disabled={isMessageLoading}>
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
            disabled: isMessageLoading,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '30px',
            },
          }}
        />
      </Box>
    </Container>
  );
}

export default Chat;
