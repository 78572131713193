import { createContext, useContext, useState } from 'react';
import axiosServices from '../utils/axiosServices';

// Create the AuthContext
export const AuthContext = createContext(null);

// AuthProvider component
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Retrieve the user from localStorage if it exists
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const login = async (userData) => {
    try {
      const response = await axiosServices.post('/auth/login', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const token = response.data.token; // Adjust this to match your API response structure

      if (token) {
        localStorage.setItem('token', token); // Store the token in localStorage
        console.log('Token stored in localStorage:', token);
      } else {
        console.error('No token found in response.');
      }
      setUser(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
      return response;
    } catch (error) {
      console.error('Error during login:', error);
      return error;
    }
  };

  const register = async (userData) => {
    try {
      const response = await axiosServices.post('/users', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setUser(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
  };

  return <AuthContext.Provider value={{ user, setUser, login, register, logout }}>{children}</AuthContext.Provider>;
};

// Custom hook to use AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Export AuthProvider
export default AuthProvider;
