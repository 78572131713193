import { useState } from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';

export const useConfirmationPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [cancelCallback, setCancelCallback] = useState(null);
  const [modalProps, setModalProps] = useState({
    confirmButtonLabel: 'OK',
    cancelButtonLabel: 'Cancel',
  });

  const openConfirmationPopup = ({ confirmButtonLabel = 'OK', cancelButtonLabel = 'Cancel', onConfirm, onCancel }) => {
    setConfirmCallback(() => onConfirm);
    setCancelCallback(() => onCancel);
    setModalProps({
      confirmButtonLabel,
      cancelButtonLabel,
    });
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const handleConfirm = () => {
    if (confirmCallback) confirmCallback();
    closePopup();
  };

  const handleCancel = () => {
    if (cancelCallback) cancelCallback();
    closePopup();
  };

  const ConfirmationModal = (
    <Modal open={isOpen} onClose={closePopup}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          padding: 2, // Reduced padding
          borderRadius: 2,
          boxShadow: 24,
          minWidth: 450,
          textAlign: 'left',
          minHeight: 200,
          display: 'flex',
          flexDirection: 'column', // Stack children vertically
        }}
      >
        <Typography
          variant='h6'
          gutterBottom
          sx={{
            borderBottom: '1px solid lightgray', // Light bottom border for the heading area
            paddingBottom: 1,
            marginBottom: 2,
          }}
        >
          Are you sure?
        </Typography>
        <Typography variant='body1' sx={{ mb: 2 }}>
          Do you really want to delete this project?
        </Typography>
        <Box sx={{ flexGrow: 1 }} /> {/* This will push the buttons to the bottom */}
        <Box display='flex' justifyContent='flex-end' gap={2}>
          <Button onClick={handleCancel} variant='outlined' color='primary'>
            {modalProps.cancelButtonLabel}
          </Button>
          <Button onClick={handleConfirm} variant='contained' color='error'>
            {modalProps.confirmButtonLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  return {
    openConfirmationPopup,
    ConfirmationModal,
  };
};
