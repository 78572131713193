import { Add, CalendarToday } from "@mui/icons-material";
import {
  Typography,
  Button,
  Container,
  Card,
  CardContent,
  Box,
  Snackbar,
  SnackbarContent,
  Chip,
  CircularProgress,
} from "@mui/material";
import BasicModal from "../components/modal";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import AddProject from "./AddProject";
import axiosServices from "../utils/axiosServices";
import { useAuth } from "../context/AuthContext";
import Mermaid from "./Mermaid";
import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useConfirmationPopup } from "../components/openconfrmationmodel";

const projectStatusMap = {
  "Stage 1 Completed": "Completed",
  "Error: Took more than 7 hrs to run": "Error",
  " Summary being generated": "In Progress",
};

const projectStatusColorMap = {
  Completed: {
    color: "#5297c0",
    borderColor: "#5297c0",
  },
  Error: {
    color: "#b82811",
    borderColor: "#b82811",
  },
  "In Progress": {
    color: "#FFC107",
    borderColor: "#FFC107",
  },
};

const getProjectStatus = (status) => {
  const processedStatus = projectStatusMap[status] || status;
  if (
    processedStatus?.toLowerCase().includes("error") ||
    processedStatus?.toLowerCase().includes("no summaries generated")
  ) {
    return "Error";
  }
  return processedStatus;
};

function Loader() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <CircularProgress />
    </Box>
  );
}

function Projects() {
  const { openConfirmationPopup, ConfirmationModal } = useConfirmationPopup();
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalContent, setModalContent] = useState();
  const [modalHeader, setModalHeader] = useState();
  const [modalWidth, setModalWidth] = useState();
  const [modalHeight, setModalHeight] = useState();

  const getAllProjects = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosServices.get(
        `/projects?email=${user?.user?.email}`
      );
      if (response.data && response.data.length) {
        setProjects(response.data);
      } else {
        setProjects([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.user?.email]);

  useEffect(() => {
    getAllProjects();
  }, [getAllProjects]);

  const openTheModal = (modalPurpose, projectId = null) => {
    if (modalPurpose === "addProject") {
      setModalHeader("Add a new Project");
      setModalWidth("400px");
      setModalHeight("auto");
      setModalContent(
        <AddProject
          onSuccess={handleAddProjectSuccess}
          onFailure={handleAddProjectFailure}
        />
      );
    } else {
      setModalHeader("Mermaid Diagram");
      setModalWidth("100%");
      setModalHeight("100%");
      setModalContent(<Mermaid projectId={projectId} />);
    }
    setIsModalOpen(true);
  };

  const handleAddProjectSuccess = () => {
    setSnackbarMessage("Project created successfully");
    setSnackbarOpen(true);
    setIsModalOpen(false);
    getAllProjects();
  };

  const handleAddProjectFailure = () => {
    setSnackbarMessage("Project creation failed");
    setSnackbarOpen(true);
    setIsModalOpen(false);
    getAllProjects();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleChat = (project) => {
    navigate("/chat", { state: { project } });
  };

  const handleSummary = async (projectId) => {
    setIsLoading(true);
    try {
      const input = { email: user?.user?.email, projectId: projectId };
      const response = await axiosServices.post(`/projects/summary`, input);
      if (response.status === 200) {
        setSnackbarMessage(response.data?.message);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setSnackbarMessage("Failed to send email. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = (projectId) => {
    const project = projects.find((proj) => proj.project_id === projectId);
    const projectName = project ? project.project_name : "Project";

    openConfirmationPopup({
      confirmButtonLabel: "Yes",
      cancelButtonLabel: "No",
      onConfirm: async () => {
        try {
          const response = await axiosServices.post(`/projects/delete`, {
            projectId,
            email: user?.user?.email,
          });

          if (response.status === 200) {
            setSnackbarMessage(`Project "${projectName}" has been deleted.`);
            setSnackbarOpen(true);
            setProjects((prevProjects) =>
              prevProjects.filter((project) => project.project_id !== projectId)
            );
          } else {
            throw new Error("Failed to delete the project.");
          }
        } catch (error) {
          console.error("Delete failed:", error);
          setSnackbarMessage("Failed to delete the project.");
          setSnackbarOpen(true);
        }
      },
      onCancel: () => {
        setSnackbarMessage("Delete operation canceled");
        setSnackbarOpen(true);
      },
    });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ m: 0, p: 2, py: 2 }}>
      {isLoading ? (
        <Loader />
      ) : projects.length === 0 ? (
        // Centered layout for new users with no projects
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          height="80vh"
          textAlign="center"
        >
          <Box
            sx={{
              p: 4,
              borderRadius: 2,
              boxShadow: 2,
              bgcolor: "background.paper",
              maxWidth: 500,
              width: "100%",
            }}
          >
            <Typography variant="h5" sx={{ mb: 3, color: "text.primary" }}>
              Start harmonizing your projects!
            </Typography>
            <Button
              size="large"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => openTheModal("addProject")}
              fullWidth
              sx={{
                fontSize: "1rem",
                fontWeight: "bold",
                py: 1.5,
              }}
            >
              Add a New Project
            </Button>
          </Box>
        </Box>
      ) : (
        // Project grid layout if projects are available
        <>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mb={2}
            justifyContent="space-between"
          >
            <Typography variant="h4" sx={{ color: "#1E293B" }}>
              All Projects
            </Typography>
            <Button
              size="medium"
              variant="contained"
              color="primary"
              startIcon={<Add />}
              onClick={() => openTheModal("addProject")}
            >
              Add a new project
            </Button>
          </Box>

          <Box
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))"
            gap={2}
          >
            {projects
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((project) => {
                const projectStatus = getProjectStatus(project.status);
                const isInProgress = projectStatus
                  .toLowerCase()
                  .includes("in progress");
                return (
                  <Card
                    key={project.project_id}
                    sx={{
                      minHeight: 200,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      boxShadow: 3,
                      padding: 2,
                      paddingTop: 1,
                      borderRadius: "10px",
                      border: "1px solid #CBD5E1",
                      position: "relative",
                    }}
                  >
                    <CardContent sx={{ flex: 1, paddingTop: 1, pl: 0 }}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {project.project_name}
                        </Typography>
                      </Box>
                    </CardContent>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{ paddingTop: 1, paddingBottom: 1, pl: 0, pr: 1 }}
                    >
                      <Box display="flex" alignItems="center">
                        <CalendarToday
                          sx={{ color: "#7d838e", mr: 0.5, fontSize: "1rem" }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#7d838e",
                            fontWeight: "bold",
                          }}
                        >
                          {new Date(project.created_at).toLocaleDateString()}
                        </Typography>
                      </Box>
                      {projectStatus !== "Completed" && (
                        <Chip
                          label={
                            isInProgress ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: 1,
                                }}
                              >
                                <CircularProgress
                                  color="secondary"
                                  size={12}
                                  sx={{
                                    animationDuration: "0.51s",
                                  }}
                                />
                                <span>In Progress</span>
                              </Box>
                            ) : (
                              projectStatus
                            )
                          }
                          variant="outlined"
                          size="small"
                          sx={{
                            fontSize: "0.75rem",
                            fontWeight: "bold",
                            color: projectStatusColorMap[projectStatus]?.color,
                            borderColor:
                              projectStatusColorMap[projectStatus]?.borderColor,
                          }}
                        />
                      )}
                    </Box>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        borderTop: "1px solid #e0e0e0",
                        paddingTop: 1,
                        pl: 0,
                        pr: 1,
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => handleSummary(project.project_id)}
                      >
                        Summary
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          openTheModal("mermaid_diagram", project.project_id)
                        }
                      >
                        Mermaid
                      </Button>
                      <Box display="flex" alignItems="center">
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => handleChat(project)}
                        >
                          Chat Now
                        </Button>

                        <IconButton
                          onClick={() => handleDelete(project.project_id)}
                          sx={{
                            color: "#b82811",
                            ml: 1, // Add some margin to the left of the delete icon to space it from the button
                          }}
                          size="small"
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Card>
                );
              })}
          </Box>
        </>
      )}

      <BasicModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        headerText={modalHeader}
        content={modalContent}
        width={modalWidth}
        height={modalHeight}
      />
      {ConfirmationModal}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={snackbarOpen}
        autoHideDuration={1500}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "teal",
          }}
          message={<span id="message-snackbar">{snackbarMessage}</span>}
        />
      </Snackbar>
    </Container>
  );
}

export default Projects;
