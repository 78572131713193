import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
  palette: {
    primary: {
      main: "#008000", //green
      light: "#feb47b", // dont use
    },
    secondary: {
      main: "#FFC107", // Vibrant golden yellow
      light: "#FFE082", // Soft pastel yellow
    },
    background: {
      dark: "#BDBDBD", // gark grey
      light: "#F7F7F7", // light grey - almost off white
    },
  },
});

export default theme;
